import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Tabs, Tab, Container } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/LCEPL-resized-logo.png";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState("home");
  const [mobileView, setMobileView] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event, newValue) => {
    // Handling career page navigation
    if (newValue === "career" && location.pathname !== "/career") {
      navigate("/career");

      window.scrollTo({ top: 0, behavior: "smooth" }); // Reset scroll to top
    } 
    // Handling INVESTORRELATIONS page navigation
    else if (newValue === "INVESTORRELATIONS" && location.pathname !== "/INVESTORRELATIONS") {
      navigate("/INVESTORRELATIONS");
      window.scrollTo({ top: 0, behavior: "smooth" }); // Reset scroll to top
    } 
    // Handling About Us and other sections when on Home
    else if (
      location.pathname === "/INVESTORRELATIONS" || location.pathname === "/career") {
      navigate("/"); // Go to home first
      setTimeout(() => {
        setValue(newValue);
        scrollToSection(newValue); // Scroll to respective section
      }, 100);
    } 
    // Handling scrolling on the home page directly
    else {
      setValue(newValue);
      const scrollElement = document.getElementById(newValue);
      if (scrollElement) {
        window.scrollTo({
          top: scrollElement.offsetTop - 10, // Adjust this for header height
          behavior: "smooth",
        });
      }
    }

  };

  // Scroll to section helper
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 10, // Adjust as needed
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener("resize", setResponsiveness);

    const setInitialPath = () => {
      if (location.pathname === "/career") {
        setValue("career");
        window.scrollTo({ top: 0, behavior: "smooth" }); // Reset scroll to top on career page
      } else if (location.pathname === "/INVESTORRELATIONS") {
        setValue("INVESTORRELATIONS");
        window.scrollTo({ top: 0, behavior: "smooth" }); // Reset scroll to top on INVESTOR_RELATIONS page
      } else if (location.pathname === "/") {
        setValue("home");
      }
    };

    setInitialPath();
    return () => {
      window.removeEventListener("resize", setResponsiveness);
    };
  }, [location.pathname]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id) => {
    if (id) {
      if (id === "career" && location.pathname !== "/career") {
        navigate("/career");
        window.scrollTo({ top: 0, behavior: "smooth" }); // Reset scroll to top
      } else if (id === "INVESTORRELATIONS" && location.pathname !== "/INVESTORRELATIONS") {
        navigate("/INVESTORRELATIONS");
        window.scrollTo({ top: 0, behavior: "smooth" }); // Reset scroll to top
      } else if (location.pathname !== "/") {
        navigate("/");
        setTimeout(() => {
          scrollToSection(id);
        }, 100);
      } else {
        scrollToSection(id);
      }
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBar sx={{ background: "white" }}>
        <Container>
          <Toolbar>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {mobileView && (
                  <IconButton
                    sx={{ color: "#448bb4", marginRight: "20px" }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => handleClose("home")}>Home</MenuItem>
                  <MenuItem onClick={() => handleClose("aboutus")}>
                    About Us
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("projects")}>
                    Projects
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("services")}>
                    Services
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("career")}>
                    Career
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("subcontractor")}>
                    SubContractor
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("contactus")}>
                    Contact Us
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("INVESTORRELATIONS")}>INVESTOR RELATIONS</MenuItem>
                </Menu>
                <img
                  src={Logo}
                  alt="LCEPl"
                  width={mobileView ? "200px" : "300px"}
                />
              </div>
              {!mobileView && (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="wrapped label tabs example"
                >
                  <Tab value="home" label="Home" />
                  <Tab value="aboutus" label="About Us" />
                  <Tab value="projects" label="Projects" />
                  <Tab value="services" label="Services" />
                  <Tab value="career" label="Career" />
                  <Tab value="contactus" label="Contact Us" />
                  <Tab value="INVESTORRELATIONS" label="INVESTOR RELATIONS" />
                </Tabs>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}